
// import { useRoute, useRouter } from "vue-router";
import Aside from "./aside/Aside"
import Header from "./header/Header"
import Footer from "./footer/Footer"
// import KTLoader from "./components/Loader";

export default {
  name: "Layout",
  props: {
    asideEnabled: Boolean,
  },
  components: {
    Aside,
    Header,
    Footer,
    // Loader,
  },
  methods : {
    pageTitle() {
      return 'Test'
    }
  },
  // setup() {
  //   const store = useStore();
  //   const route = useRoute();
  //   const router = useRouter();
  // },
}

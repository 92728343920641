
import { mapState } from 'vuex'
//import Search from "@/layout/header/partials/Search"
//import NotificationsMenu from "@/layout/header/partials/NotificationsMenu"
//import QuickLinksMenu from "@/layout/header/partials/QuickLinksMenu"
//import UserMenu from "@/layout/header/partials/UserMenu"

export default {
  name: "Topbar",
  components: {
    //NotificationsMenu,
    //QuickLinksMenu,
    //UserMenu,
    //Search,
  },
  computed: {
    ...mapState('users', [
      'getCurrentProfileResult',
    ]),
  },
}


import Menu from "./Menu"
import Topbar from "./Topbar"

export default {
  name: 'Header',
  props: {
    title: String,
    showAddTask: Boolean,
    isLoggedIn: Boolean,
  },
  components: {
    Menu,
    Topbar,
  },
  computed: {
    isHeaderSticky() {
      if (window.innerWidth > 768) {
        return false;
      } else {
        return false;
      }
    },
  },
}

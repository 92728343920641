const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Assets",
        route: "/assets",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Inspections",
        route: "/inspections",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Organizations",
        route: "/organizations",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
        isProfileVendorRequred: true,
      },
      {
        heading: "Users",
        route: "/users",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
        isProfileVendorRequred: true,
      },
    ],
  },
];

export default DocMenuConfig